<template>
  <div>
    <SubHeaderPodcasts
      @searchPrograms="searchPrograms"
      @changeFilterPlaylist="changeFilterPlaylist"
      @refreshProgramList="refreshProgramList"
      :canChangeOrder="canChangeOrder"
      :isDomainVisible="isDomainVisible"
    />

    <AlertExpirePassword />

    <PodcastsDraggable
      :podcasts="podcastsSections"
      :domainId="filters.domains[0]"
      @changedOrder="changedOrder"
    />

    <div class="kt-container">
      <ContentLoad
        v-bind="{
          display: pageLoading
        }"
      ></ContentLoad>

      <ContentLoadError
        v-bind="{
          display: pageLoadingError
        }"
      ></ContentLoadError>

      <b-pagination
        v-if="needPaginations"
        v-model="filtersRequest.page"
        :total-rows="totalRows"
        :per-page="filtersRequest.limit"
        @change="changePage"
      ></b-pagination>

      <div
        v-if="hasPrograms"
        class="d-flex flex-column flex-sm-row align-items-center align-items-sm-start flex-wrap"
      >
        <div
          class="container-programs mb-5 mx-2"
          v-for="(podcast, index) in podcasts"
          :key="index"
        >
          <ProgramsThumbails
            :podcast="podcast"
            :lastProgramImport="idLastProgramImport"
            @deleteProgram="modalDeleteProgram"
            @openProgram="modalProgram"
            @homedisplayProgram="homedisplayProgram"
            @importstatusProgram="importstatusProgram"
            @cleanimages="cleanimagesProgram"
            @modalEpisodes="modalEpisodes"
          />
        </div>
      </div>

      <b-modal
        header-close-content
        id="modal-episodes"
        size="xl"
        title="Episodes List"
      >
        <template #modal-footer="{ close }">
          <button class="btn border rounded mr-3" @click="close">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
          </button>
        </template>

        <ListingEpisodesV2
          :header="headers"
          :items="itemsEpisodes"
          :isLoading="isLoadingEpisodesFromPrograms"
          :currentPage="currentPageEpisodesList"
          :totalItems="totalItemsEpisodes"
          :program="podcast"
          @loadEpisodes="loadEpisodesFromPrograms"
        />
      </b-modal>

      <b-modal
        id="modal-delete-program"
        :title="$tc('ADVANCED_SETTINGS.CONTENT.DELETE.TITLE')"
        hide-header-close
      >
        <p class="my-4">{{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.LABEL") }}</p>

        <template #modal-footer="{ close }">
          <button
            class="btn border rounded mr-3"
            :disabled="isLoadingDelete"
            @click="close"
          >
            {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.CANCEL") }}
          </button>

          <button
            class="btn btn-danger mr-3"
            :disabled="isLoadingDelete"
            @click="deleteProgram(close)"
          >
            <template v-if="!isLoadingDelete">
              {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE.SUBMIT") }}
            </template>

            <template v-if="isLoadingDelete">
              <b-spinner label="spinning"></b-spinner>
            </template>
          </button>
        </template>
      </b-modal>

      <b-modal
        header-close-content
        id="modal-broadcast"
        size="lg"
        :title="$tc('ADVANCED_SETTINGS.CONTENT.BROADCAST.TITLE')"
      >
        <template #modal-footer="{ close }">
          <button class="btn border rounded mr-3" @click="close">
            {{ $tc("ADVANCED_SETTINGS.CONTENT.CLOSE") }}
          </button>
        </template>

        <div class="flex-row">
          <fieldset class="border p-2 px-4 mb-4">
            <legend class="w-auto" style="color: #ff961d">
              &nbsp;Access the latest available episode&nbsp;
            </legend>
            <!--
            <div class="row flex-row">
              <div class="col-lg-10">
                <h6 class="card-title">Embed Player</h6>
                <p class="card-text font-italic">
                  Copy and paste this code if you want to embed the latest
                  episode of your podcast into an article. To access a specific
                  episode, visit the Episode list.
                </p>
              </div>
              <div class="mx-auto my-auto">
                <a href="#" @click.stop.prevent="copyToClipboard()" disabled>
                  <v-icon size="30" class="mx-auto pl-3" disabled
                    >mdi-content-copy</v-icon
                  >
                </a>
              </div>
            </div>
            -->
            <div class="row flex-row">
              <div class="col-lg-10">
                <h6 class="card-title">Link</h6>
                <p class="card-text font-italic">
                  Copy and paste this link if you want to broadcast your latest
                  episode on your usual distribution channels: social media,
                  newsletter, push notifications, etc.
                </p>
              </div>
              <div class="mx-auto my-auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      href="#"
                      v-bind="attrs"
                      @click="
                        showTooltip(on, $event);
                        copyToClipboard(getLatestEpisode);
                      "
                    >
                      <v-icon size="30" class="mx-auto pl-3"
                        >mdi-content-copy</v-icon
                      >
                    </a>
                  </template>
                  <span>Copied</span>
                </v-tooltip>
              </div>
            </div>
          </fieldset>

          <fieldset class="border p-2 px-4 mb-4">
            <legend class="w-auto" style="color: #ff961d">
              &nbsp;Access a specific episode&nbsp;
            </legend>

            <div class="row flex-row">
              <div class="col-lg-10">
                <p class="card-text font-italic">
                  To broadcast a specific episode (either via embed or using the
                  link), go to the list of episodes for this program.
                </p>
              </div>
              <div class="mx-auto my-auto">
                <a href="#" @click="modalEpisodes(podcast)">
                  <v-icon size="30" class="mx-auto pl-3">
                    mdi-open-in-new</v-icon
                  >
                </a>
              </div>
            </div>
          </fieldset>

          <fieldset class="border p-2 px-4 mb-4">
            <legend class="w-auto" style="color: #ff961d">
              &nbsp;Access the web page of your podcast&nbsp;
            </legend>

            <div class="row flex-row">
              <div class="col-lg-10">
                <p class="card-text font-italic">
                  Click on this link to access the web page of your program.
                </p>
              </div>
              <div class="mx-auto my-auto">
                <a href="#" @click="navigate(getAudioHub)">
                  <v-icon size="30" class="mx-auto pl-3">
                    mdi-open-in-new</v-icon
                  >
                </a>
              </div>
            </div>
          </fieldset>

          <fieldset class="border p-2 px-4 mb-4">
            <legend class="w-auto" style="color: #ff961d">
              &nbsp;Get the RSS feeds of your program&nbsp;
            </legend>

            <div class="row flex-row">
              <div class="col-lg-10">
                <h6 class="card-title">Podcast Feed</h6>
                <p class="card-text font-italic">Orignal RSS feed</p>
              </div>
              <div class="mx-auto my-auto">
                <a href="#" @click.stop.prevent="navigate(getOrignalUrl)">
                  <v-icon size="30" class="mx-auto pl-3"
                    >mdi-open-in-new</v-icon
                  >
                </a>
              </div>
            </div>

            <div class="row flex-row">
              <div class="col-lg-10">
                <h6 class="card-title">RSS Feed</h6>
                <p class="card-text font-italic">
                  Retrieve the RSS feed code of your program here.
                </p>
              </div>
              <div class="mx-auto my-auto">
                <a href="#" @click="navigate(getRssFeed)">
                  <v-icon size="30" class="mx-auto pl-3">
                    mdi-open-in-new</v-icon
                  >
                </a>
              </div>
            </div>
          </fieldset>
        </div>
      </b-modal>

      <b-modal
        id="modal-homedisplay"
        title="Change home visiblity"
        header-close-content
        no-close-on-backdrop
      >
        <template #modal-footer="{ close }">
          <button class="btn border rounded mr-3" @click="close">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
          </button>

          <button class="btn btn-danger mr-3" @click="homedisplay">
            <template>
              {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CONFIRM") }}
              <b-spinner
                v-if="isLoadingSubmit"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </template>
          </button>
        </template>

        <p
          v-html="
            $t('EDIT_PODCAST.HOMEDISPLAY_CONFIRMATION', {
              action: podcast.homeDisplay ? 'offline' : 'online',
              type: podcast.playlist === null ? 'program' : 'playlist',
              name: podcast.name
            })
          "
        ></p>
      </b-modal>

      <b-modal
        id="modal-importstatus"
        title="Change import status"
        header-close-content
        no-close-on-backdrop
      >
        <template #modal-footer="{ close }">
          <button class="btn border rounded mr-3" @click="close">
            {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CANCEL") }}
          </button>

          <button class="btn btn-danger mr-3" @click="importstatus">
            <template>
              {{ $tc("ADVANCED_SETTINGS.INVOICING.USERS.BUTTON.CONFIRM") }}
              <b-spinner
                v-if="isLoadingSubmit"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </template>
          </button>
        </template>

        <p
          v-html="
            $t('EDIT_PODCAST.IMPORT_CONFIRMATION', {
              action: podcast.status ? 'disabled' : 'enabled',
              type: 'program',
              name: podcast.name
            })
          "
        ></p>
      </b-modal>

      <div
        v-if="!hasPrograms && !pageLoading"
        class="d-flex justify-content-center"
      >
        <span>{{ $tc("PROGRAMS_PAGE.NO_PROGRAMS_FOUND") }}</span>
      </div>

      <b-pagination
        v-if="needPaginations"
        v-model="filtersRequest.page"
        :total-rows="totalRows"
        :per-page="filtersRequest.limit"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import {
  getAllProgramsPremium,
  getAllProgramsWithSections
} from "@/api/podcasts/getters.js";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ContentLoad from "@/views/partials/widgets/ContentLoad";
import ProgramsThumbails from "@/components/settings/ProgramsThumbails.vue";
import ContentLoadError from "@/views/partials/widgets/ContentLoadError";
import { mapGetters } from "vuex";
import SubHeaderPodcasts from "@/components/subheaders/SubHeaderPodcasts";
import AlertExpirePassword from "@/components/AlertExpirePassword";
import PodcastsDraggable from "@/components/PodcastsDraggable";
import {
  deleteProgram,
  cleanImages as cleanImagesProgram
} from "@/api/programs/setters.js";
import { updateFilter } from "@/common/filters.js";
import {
  getEpisodesFromPrograms,
  updateProgramV2
} from "@/api/programs/getters";
import ListingEpisodesV2 from "@/components/settings/ListingEpisodesV2";
import { headersEpisodeList } from "@/components/settings/formsPogram/programs.config";

export default {
  components: {
    ContentLoadError,
    ContentLoad,
    ProgramsThumbails,
    SubHeaderPodcasts,
    AlertExpirePassword,
    PodcastsDraggable,
    ListingEpisodesV2
  },

  data() {
    return {
      podcast: {},
      podcasts: [],
      podcastsSections: [],
      pageLoading: true,
      pageLoadingError: false,
      totalRows: 0,
      podcastChange: [],
      isLoadingDelete: false,
      programIdDelete: "",
      isFirstLoad: true,
      timeOuSearch: {},
      idLastProgramImport: null,
      filtersRequest: {
        page: 1,
        limit: 50
      },
      isLoadingSubmit: false,
      isDomainVisible: false,
      isLoadingEpisodesFromPrograms: false,
      limitPerPage: 10,
      totalItemsEpisodes: 0,
      itemsEpisodes: [],
      currentPageEpisodesList: 0,
      headers: headersEpisodeList
    };
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Podcasts",
        pageCategory: "Account",
        header: { actions: ["add_podcast"] }
      }
    ]);
  },

  computed: {
    ...mapGetters(["filters", "filtersOptions"]),

    getRssFeed() {
      const baseUrl = this.podcast.platformUrl;
      return `${baseUrl}/feed/rss/website.xml`;
    },

    getAudioHub() {
      const baseUrl = this.podcast.platformUrl;
      return `${baseUrl}`;
    },

    getOrignalUrl() {
      return this.podcast.url;
    },

    getLatestEpisode() {
      const baseUrl = this.podcast.platformUrl;
      return `${baseUrl}/episodes/latest`;
    },

    hasPrograms() {
      return this.podcasts.length > 0;
    },

    needPaginations() {
      return this.hasPrograms && this.filtersRequest.limit <= this.totalRows;
    },

    canChangeOrder() {
      return this.podcasts.length > 1;
    }
  },

  methods: {
    showTooltip({ mouseenter, mouseleave }, e) {
      clearTimeout(this._timerId);
      mouseenter(e);
      this._timerId = setTimeout(() => mouseleave(e), 900);
    },
    async loadEpisodesFromPrograms({ page = 1, search = "" }) {
      this.isLoadingEpisodesFromPrograms = true;

      try {
        const response = await getEpisodesFromPrograms(
          { page, limit: this.limitPerPage, search },
          this.podcast.id,
          this.axios
        );
        this.itemsEpisodes = response.data;
        this.totalItemsEpisodes = response.total_items;
      } catch (error) {
        console.error("---ERROR-EPISODES-PROGRAM---");
        console.error(error);
      }

      this.isLoadingEpisodesFromPrograms = false;
    },

    homedisplay() {
      let message = this.$tc("EDIT_PODCAST.MESSAGE_ONLINE");

      if (this.podcast.homeDisplay) {
        message = this.$tc("EDIT_PODCAST.MESSAGE_OFFLINE");
      }

      let payload = { homeDisplay: !this.podcast.homeDisplay };

      this.updateProgramField(payload, "modal-homedisplay", message);
    },

    importstatus() {
      let message = this.$tc("EDIT_PODCAST.MESSAGE_IMPORT_ENABLED");

      if (this.podcast.status) {
        message = this.$tc("EDIT_PODCAST.MESSAGE_IMPORT_DISABLED");
      }

      let payload = { status: !this.podcast.status };

      this.updateProgramField(payload, "modal-importstatus", message);
    },

    async updateProgramField(payload, modalID, message) {
      this.isLoadingSubmit = true;
      let variant = "success";

      try {
        await updateProgramV2(this.axios, this.podcast.id, payload);
        await this.getPodcasts();
      } catch (error) {
        console.error("---ERROR-PROGRAM-UPDATE---");
        console.error(error);

        message = this.$tc(
          "ADVANCED_SETTINGS.INVOICING.USERS.TOAST.ERROR.MESSAGE"
        );
        variant = "danger";
      }

      this.flashMessage(message, variant);
      this.hideModal(modalID);
    },

    refreshProgramList() {
      this.getPodcasts();
      this.getProgramsWithSections(this.filters.domains[0]);
    },

    flashMessage(message, variant = "success") {
      this.showToast({
        title: this.$tc("PODCASTS.TOAST.TITLE"),
        message: message,
        variant: variant
      });
    },

    hideModal(id) {
      this.isLoadingSubmit = false;
      this.$bvModal.hide(id);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },

    navigate(link) {
      window.open(link);
    },

    modalEpisodes(podcast) {
      this.podcast = podcast;
      this.loadEpisodesFromPrograms({ page: 1 });
      this.$bvModal.show("modal-episodes");
    },

    async getPodcasts(programId = null) {
      const domains = this.filters.domains[0];
      this.pageLoading = true;
      this.pageLoadingError = false;

      if (programId) {
        this.idLastProgramImport = programId;
      }

      try {
        this.filtersRequest.domains = domains;
        const ordered = "position";
        const filtersRequest = {
          ...this.filtersRequest,
          ordered
        };
        const response = await getAllProgramsPremium(
          filtersRequest,
          this.axios
        );
        this.podcasts = response.data.filter(element => !element.parentId);

        const positionOnHome = (program, currentDomainId) => {
          const programDomains = program?.programDomains;

          if (!programDomains || programDomains.length === 0) {
            return undefined;
          }

          if (programDomains.length === 1) {
            return programDomains[0]?.position;
          }

          const programDomain = programDomains.find(
            programDomain => programDomain.domain.id === currentDomainId
          );

          if (!programDomain) {
            return undefined;
          }
          return programDomain?.position;
        };

        if (domains) {
          this.podcasts.sort((programA, programB) => {
            const positionOnHomeA = positionOnHome(programA, domains);
            const positionOnHomeB = positionOnHome(programB, domains);

            return positionOnHomeA - positionOnHomeB;
          });
        }

        this.totalRows = response.total_items;
      } catch (error) {
        console.error("---ERROR-PODCAST---");
        console.error(error);
        this.pageLoadingError = true;
      }
      this.pageLoading = false;
    },

    async getProgramsWithSections(domainId) {
      try {
        const response = await getAllProgramsWithSections(
          { domain: domainId },
          this.axios
        );

        this.podcastsSections = [...response.data];
      } catch (error) {
        console.error("---ERROR-PROGRAMS-SECTIONS---");
        console.error(error);
      }
    },

    changePage(page) {
      this.filtersRequest.page = page;

      this.getPodcasts();
    },

    changedOrder() {
      this.showToast({
        title: this.$tc("ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.TITLE"),
        message: this.$tc(
          "ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.MESSAGE_CHANGE_ORDER"
        ),
        variant: "success"
      });
    },

    modalDeleteProgram(programId) {
      this.$bvModal.show("modal-delete-program");
      this.programIdDelete = programId;
    },

    modalProgram(program) {
      this.podcast = program;
      this.$bvModal.show("modal-broadcast");
    },

    homedisplayProgram(program) {
      this.podcast = program;
      this.$bvModal.show("modal-homedisplay");
    },

    importstatusProgram(program) {
      this.podcast = program;
      this.$bvModal.show("modal-importstatus");
    },

    cleanimagesProgram({ id }) {
      cleanImagesProgram(id, this.axios)
        .then(() => {
          this.$bvToast.toast(`Image cleaned`, {
            title: `Image cleaner`,
            solid: true,
            variant: "success",
            appendToast: false,
            autoHideDelay: 3000
          });
        })
        .catch(error => {
          const errors =
            error.response &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("errors")
              ? error.response.data.errors
              : [`Image cleaned error`];

          const text = errors.join(", ");

          this.$bvToast.toast(`${text}`, {
            title: `Image cleaner`,
            solid: true,
            variant: "danger",
            appendToast: false
          });
        });
    },

    showToast({ title, variant, message }) {
      const h = this.$createElement;
      const id = `toaster-message`;
      const $contentToaster = h("span", message);

      this.$bvToast.toast([$contentToaster], {
        id: id,
        title: title,
        variant: variant,
        noCloseButton: false
      });
    },

    searchPrograms(value) {
      clearTimeout(this.timeOuSearch);

      if (value.length === 0) {
        delete this.filtersRequest.search;
      } else {
        this.filtersRequest.search = value.toLowerCase();
      }

      this.timeOuSearch = setTimeout(() => {
        this.getPodcasts();
      }, 1000);
    },

    changeFilterPlaylist(value) {
      if (!value) {
        delete this.filtersRequest.isplaylist;
      } else {
        this.filtersRequest.isplaylist = value;
      }
      this.getPodcasts();
    },

    async deleteProgram() {
      this.isLoadingDelete = true;

      try {
        await deleteProgram(
          { domain: this.filters.domains[0] },
          this.programIdDelete,
          this.axios
        );

        this.getPodcasts();
        this.getProgramsWithSections(this.filters.domains[0]);

        this.programIdDelete = "";

        this.showToast({
          title: this.$tc("ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.TITLE"),
          message: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.SUCCESS.MESSAGE_DELETE"
          ),
          variant: "success"
        });

        this.$bvModal.hide("modal-delete-program");
      } catch (error) {
        console.error("---ERROR-DELETE-PROGRAM---");
        console.error(error);
        this.$bvModal.hide("modal-delete-program");
        this.programIdDelete = "";
      }

      this.isLoadingDelete = false;
    }
  },

  watch: {
    filters(change) {
      this.podcasts = [];
      //'isFirstLoad' avoid double loading of data when loading the page
      if (
        (this.isFirstLoad && change.domains.length > 0) ||
        !this.isFirstLoad
      ) {
        this.isFirstLoad = false;
        this.getPodcasts();
        this.getProgramsWithSections(change.domains[0]);
      }
    },

    "filtersOptions.domains.items"(domainsList) {
      let domains = [];
      if (domainsList && domainsList.length > 0) {
        this.isDomainVisible = true;
        domains = [domainsList[0]?.id];
      } else {
        this.isDomainVisible = false;
        this.pageLoading = false;
        this.getPodcasts(); // no domain no sections
      }

      //Needed for select one domains
      //TODO: don't manage the filters with the store and this line can be remove, it's just a "bandage" for the moment
      updateFilter(this.filters, domains, this.$store, "domains");
    }
  }
};
</script>

<style scoped>
.container-programs {
  width: 250px;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 37px;
  background-color: #337dd6;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 13px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -20px;
  left: -10px;
}

.ribbon-top-left span {
  right: -25px;
  top: 60px;
  transform: rotate(-45deg);
}
</style>
