<template>
  <b-modal
    ref="modalOrder"
    @ok="submitOrder"
    header-close-content
    no-close-on-backdrop
    hide-footer
  >
    <div class="mb-4">
      <h1>{{ $tc("PROGRAMS_PAGE.PROGRAMS_DRAGGABLE.TITLE") }}</h1>
      <span>{{ $tc("PROGRAMS_PAGE.PROGRAMS_DRAGGABLE.LABEL_HELPER") }}</span>
    </div>
    <b-alert :show="isErrorDragging" variant="danger">{{
      $tc("PROGRAMS_PAGE.PROGRAMS_DRAGGABLE.ERROR")
    }}</b-alert>

    <details
      v-for="(item, index) in copyPodcasts"
      :key="`sections_${index}`"
      class="mb-2"
      open
    >
      <summary class="font-weight-bold mb-3">
        {{ item.name }} {{ `(${item.programDomains.length})` }}
      </summary>
      <draggable
        :list="item.programDomains"
        group="people"
        style="min-height: 52px"
      >
        <div
          v-for="(programDomain, index) in item.programDomains"
          :key="`program_${index}`"
          class="card drag mb-2 w-100"
        >
          <div class="d-inline-flex">
            <img
              :src="programDomain.program.thumbnails[64]"
              width="50"
              height="50"
              alt="Podcast image"
            />

            <div class="card-body">
              <span class="card-text">{{ programDomain.program.name }}</span>
            </div>

            <div class="mr-2 d-flex align-items-center">
              <img
                src="@/assets/media/icons/svg/Design/Horizontal.svg?inline"
                alt="Icône drag and drop card"
              />
            </div>
          </div>
        </div>
      </draggable>
    </details>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="$refs.modalOrder.hide()"
      >
        {{ $tc("PROGRAMS_PAGE.PROGRAMS_DRAGGABLE.CANCEL") }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        :disabled="isLoadingDraging"
        @click="submitOrder"
      >
        <template v-if="!isLoadingDraging">
          {{ $tc("PROGRAMS_PAGE.PROGRAMS_DRAGGABLE.SUBMIT") }}
        </template>
        <b-spinner v-if="isLoadingDraging" label="Spinning"></b-spinner>
      </button>
    </div>
  </b-modal>
</template>

<script>
import draggable from "vuedraggable";
import { sendPogramsSections } from "@/api/podcasts/setters";

export default {
  name: "PodcastsDraggable",

  components: {
    draggable
  },

  props: {
    podcasts: {
      type: Array,
      required: true
    },
    domainId: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      copyPodcasts: [],
      isLoadingDraging: false,
      isErrorDragging: false
    };
  },

  beforeMount() {
    // Emit from "SubHeaderPodcasts"
    this.$root.$on("changeOrder", () => {
      const sections = [...this.podcasts];

      for (const sectionIndex in sections) {
        const programDomains = this.podcasts[sectionIndex].programDomains;

        sections[sectionIndex].programDomains = programDomains.filter(
          programDomain =>
            !(programDomain.program?.deleted || false) &&
            !programDomain.program.parentId
        );
      }

      this.copyPodcasts = sections;
      this.isErrorDragging = false;
      this.$refs.modalOrder.show();
    });
  },

  methods: {
    async submitOrder(e) {
      this.isErrorDragging = false;
      this.isLoadingDraging = true;
      e.preventDefault();
      let body = [];

      this.copyPodcasts.forEach(section => {
        let sectionObject = {
          id: section.id,
          indexOrder: section.indexOrder,
          programs: []
        };

        section.programDomains.forEach((programDomain, index) => {
          sectionObject.programs.push({
            program: programDomain.program.id,
            domain: this.domainId,
            positionOnHome: index,
            section: section.id
          });
        });

        body.push(sectionObject);
      });

      try {
        await sendPogramsSections(body, { domain: this.domainId }, this.axios);

        this.$emit("submit", this.copyPodcasts);
        this.$refs.modalOrder.hide();

        this.$emit("changedOrder");
      } catch (error) {
        console.error("---PODCAST-ORDER---");
        console.error(error);
        this.isErrorDragging = true;
      }

      this.isLoadingDraging = false;
    }
  }
};
</script>

<style scoped>
.drag {
  cursor: move;
}

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4540c9;
}
</style>
