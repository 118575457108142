<template>
  <b-form ref="form" @submit="onSubmit" @reset="onReset" role="group" class="">
    <v-card>
      <v-card-title class="text-center">
        <span class="headline w-100"> </span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col xs="12" class="text-left mt-4">
            <label>{{
              $t("ADVANCED_SETTINGS.CONTENT.IMPORT.INPUT_FEEDURL_LABEL")
            }}</label>
            <b-form-group
              label-for="input-feed"
              :invalid-feedback="invalidFeedback"
              :state="state"
            >
              <b-form-input
                id="input-feed"
                :placeholder="
                  $t(
                    'ADVANCED_SETTINGS.CONTENT.IMPORT.INPUT_FEEDURL_PLACEHOLDER'
                  )
                "
                v-model="feedURL"
                :state="state"
                trim
                class="mt-1"
                type="url"
                @input="inputChangeHandler"
              ></b-form-input>

              <v-select
                v-if="hasAccess('cluster', 'write')"
                v-model="partition"
                :items="partitionList"
                :label="
                  $t(
                    'ADVANCED_SETTINGS.CONTENT.IMPORT.SELECT_PARTITION_PLACEHOLDER'
                  )
                "
                required
                class="mt-4"
              >
              </v-select>

              <v-select
                v-if="!domainLoading"
                v-model="seletedDomain"
                :items="domains"
                :rules="[isRequired]"
                :label="
                  $t(
                    'ADVANCED_SETTINGS.CONTENT.IMPORT.SELECT_DOMAIN_PLACEHOLDER'
                  )
                "
                item-text="name"
                return-object
                required
                class="mt-4"
              >
              </v-select>
              <div
                class="d-flex align-items-center text-center mt-4"
                v-if="domainLoading"
              >
                <strong>Domains loading...</strong>&nbsp;&nbsp;
                <b-spinner variant="primary" label="Loading"></b-spinner>
              </div>
            </b-form-group>
          </v-col>

          <v-col xs="12" v-if="0 < errors.length">
            <ul id="errors-list" class="text-danger">
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="mt-5">
        <v-spacer></v-spacer>
        <v-btn type="reset" variant="danger" class="mx-1">{{
          $t("ADVANCED_SETTINGS.CONTENT.IMPORT.BUTTON.CANCEL")
        }}</v-btn>
        <v-btn
          type="submit"
          class="mx-1 btn"
          style="
             {
              border-color: #1c195c;
              color: #ffffff;
              background-color: #1c195c;
            }
          "
          :loading="loading"
          :disabled="loading || !state || !seletedDomain"
          >{{ $t("ADVANCED_SETTINGS.CONTENT.IMPORT.BUTTON.IMPORT") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </b-form>
</template>

<script>
import MessageService from "@/common/message.service";
import { getAllDomainsPremiumV2 } from "@/api/domains/getters.js";
import { create as createProgram } from "@/api/programs/setters.js";
import { mapGetters } from "vuex";
import { hasAttributeAccess } from "@/common/config/acl/index";
import { FORM_ADVANCED } from "@/common/config/acl/programs/abac";
import { partitionList } from "@/components/settings/formsPogram/programs.config.js";

const REGEX_URL_START = /^https?:\/\//;

export default {
  name: "ImportPremiumPodcast",

  data() {
    return {
      errors: [],
      loading: false,
      state: null,
      feedURL: "",
      domains: [],
      seletedDomain: null,
      domainLoading: true,
      partition: "premium",
      partitionList
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser"
    }),

    invalidFeedback() {
      const feed = this.feedURL;
      if (feed.length >= 10 && REGEX_URL_START.test(feed)) {
        return "";
      } else {
        return this.$t(
          "ADVANCED_SETTINGS.CONTENT.IMPORT.VALIDATOR.INVALID_FEEDBACK"
        );
      }
    }
  },

  mounted() {
    this.domainLoading = true;
    getAllDomainsPremiumV2({ page: 1, limit: 100 }, this.axios)
      .then(({ data }) => {
        this.domains = data;
        this.domainLoading = false;
      })
      .catch(error => {
        console.error(error);
      });

    if (this.domains.length === 1) {
      this.seletedDomain = this.domains[0];
    }
  },

  methods: {
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        FORM_ADVANCED,
        this.currentUser.roles,
        field,
        accessType
      );
    },

    inputChangeHandler() {
      this.state =
        this.feedURL.length >= 10 && REGEX_URL_START.test(this.feedURL)
          ? true
          : false;
    },
    onSend() {
      this.$refs.form.submit();
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (this.feedURL.length >= 10 && REGEX_URL_START.test(this.feedURL)) {
        this.loading = true;

        let body = {
          url: this.feedURL,
          domain: this.seletedDomain.id
        };

        if (this.hasAccess("cluster", "write")) {
          body = {
            ...body,
            cluster: this.partition
          };
        }

        createProgram(body, this.axios, 60000)
          .then(res => {
            this.loading = false;

            MessageService.displayMessage(
              {
                message:
                  res.status === 200
                    ? this.$t(
                        "ADVANCED_SETTINGS.CONTENT.IMPORT.VALIDATOR.MESSAGESERVICE.SUCCESS_UPDATED"
                      )
                    : this.$t(
                        "ADVANCED_SETTINGS.CONTENT.IMPORT.VALIDATOR.MESSAGESERVICE.SUCCESS_IMPORTED"
                      )
              },
              {
                variant: "success"
              }
            );

            this.$emit("addContentSucceed", res.data.id);
          })
          .catch(error => {
            console.error(error);
            MessageService.displayMessage(
              {
                message: this.$t(
                  "ADVANCED_SETTINGS.CONTENT.IMPORT.VALIDATOR.MESSAGESERVICE.ERROR_IMPORTED"
                )
              },
              {
                variant: "danger"
              }
            );
          })
          .finally(() => {
            this.loading = false;
          });
        return;
      }

      MessageService.displayMessage(
        {
          message: this.$t(
            "ADVANCED_SETTINGS.CONTENT.IMPORT.VALIDATOR.MESSAGESERVICE.ERROR_NO_FILEDS_EMPTY"
          )
        },
        {
          variant: "danger"
        }
      );
    },
    onReset() {
      this.$emit("addContentGoPrevious");
    },
    isRequired(v) {
      return (
        !!v ||
        this.$t("ADVANCED_SETTINGS.CONTENT.IMPORT.VALIDATOR.ITEM_REQUIRED")
      );
    }
  }
};
</script>
