export const inputFilters = {
  name: "domains",
  label: "All Domains",
  storeValue: "domains",
  elasticSearchFilterField: "domain",
  displayText: "name",
  valueField: "id",
  isSearchBack: true,
  isMultipleSelect: false
};
