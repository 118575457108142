import { ENTITY_NAME_IN_URI } from "../config/api";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "../config/api2.0";
import { post } from "../getApi1.0";
import { update as updateV2 } from "../getApi2.0";

export const sendPodcastStats = (body, axios) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.STATS.DASHBOARD.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.STATS.DASHBOARD.name,
          value: ""
        }
      ]
    },
    {
      metrics: ""
    },
    body,
    axios
  );

export const sendPogramsSections = (body, filter, axios) =>
  updateV2(
    {
      path: ENTITY_NAME_IN_URI_V2.SECTIONS.path
    },
    body,
    axios,
    filter
  );
