<template>
  <SubHeaderContainer>
    <div class="d-flex flex-row align-items-center w-50">
      <b-form-input
        :placeholder="
          $tc('ADVANCED_SETTINGS.CONTENT.PLACEHOLDER_SEARCH_PROGRAMS')
        "
        @input="searchPrograms"
        class="form-control--grey"
        style="width: 200px"
      ></b-form-input>

      <b-form-checkbox
        name="check-button"
        class="ml-2"
        switch
        @input="changeFilterPlaylist"
      >
        {{ $tc("ADVANCED_SETTINGS.CONTENT.LABEL_FILTER_PLAYLIST") }}
      </b-form-checkbox>

      <InputFilter
        :filter="inputFilter"
        class="d-none"
        :class="{ 'd-block': isDomainVisible }"
      />
    </div>

    <div class="kt-subheader__toolbar">
      <button
        v-if="hasAccess('addContent', 'read')"
        class="btn btn-primary"
        v-on:click="openModal"
      >
        <i class="flaticon2-plus"></i
        >{{ $tc("ADVANCED_SETTINGS.CONTENT.LABEL_ADD_CONTENT") }}
      </button>

      <button
        v-if="
          filters.domains.length > 0 && isActivateDragAndDrop && canChangeOrder
        "
        class="btn btn-primary"
        @click="$root.$emit('changeOrder')"
      >
        {{ $tc("ADVANCED_SETTINGS.CONTENT.LABEL_ORDER") }}
      </button>

      <b-modal
        id="modal-add-content"
        :title="$t('ADVANCED_SETTINGS.CONTENT.CHOICES.LABEL_PROGRAM')"
        header-close-content
        hide-footer
        size="lg"
      >
        <div class="d-flex flex-row relative">
          <transition-group
            tag="div"
            name="slide"
            class="img-slider"
            style="min-height: 420px; width: 100%"
          >
            <div v-if="currentSlide === 0" key="0">
              <div
                style="position: absolute; top: 0; left: 0; bottom: 0; right: 0"
              >
                <div class="d-flex flex-row">
                  <div
                    class="d-flex flex-column justify-content-center mr-5 align-items-center text-center w-50 py-5 border rounded-lg shadow select-choice"
                    v-on:click="changeSlide(1)"
                  >
                    <v-icon size="80" style="color: #d50000" class="mb-3"
                      >mdi-podcast</v-icon
                    >

                    <h3>
                      {{
                        $tc("ADVANCED_SETTINGS.CONTENT.CHOICES.LABEL_PROGRAM")
                      }}
                    </h3>

                    <p
                      class="pb-10 w-75 text-center mt-3"
                      style="height: 100px"
                    >
                      {{
                        $tc("ADVANCED_SETTINGS.CONTENT.CHOICES.TEXT_PROGRAM")
                      }}
                    </p>
                  </div>

                  <router-link
                    :to="`/settings/content/create?type=playlist`"
                    target="_blank"
                    class="d-flex flex-column justify-content-center align-items-center text-center w-50 py-5 border rounded-lg shadow select-choice"
                  >
                    <v-icon size="80" style="color: #3f51b5" class="mb-3"
                      >mdi-playlist-play</v-icon
                    >

                    <h3>
                      {{
                        $tc("ADVANCED_SETTINGS.CONTENT.CHOICES.LABEL_PLAYLIST")
                      }}
                    </h3>

                    <p class="pb-10 w-75 mt-3" style="height: 100px">
                      {{
                        $tc("ADVANCED_SETTINGS.CONTENT.CHOICES.TEXT_PLAYLIST")
                      }}
                    </p>
                  </router-link>
                </div>
              </div>
            </div>

            <div v-if="currentSlide === 1" key="1">
              <div
                class="justify-content-center m-auto align-items-center text-center"
                style="position: absolute; top: 0; left: 0; bottom: 0; right: 0"
              >
                <ImportPremiumPodcast
                  @addContentGoPrevious="changeSlide(0)"
                  @addContentSucceed="closeModalAndReload"
                />
              </div>
            </div>
          </transition-group>
        </div>
      </b-modal>
    </div>
  </SubHeaderContainer>
</template>

<script>
import { mapGetters } from "vuex";
import SubHeaderContainer from "@/components/subheaders/SubHeaderContainer";
import InputFilter from "@/components/subheaders/filters/inputs/InputFilter";
import ImportPremiumPodcast from "@/views/partials/widgets/ImportPremiumPodcast";
import { inputFilters } from "@/common/config/filters/podcasts";
import { hasAttributeAccess } from "@/common/config/acl/index";
import { PAGE_PROGRAMS } from "@/common/config/acl/programs/abac";

export default {
  name: "SubHeaderPodcasts",

  props: {
    canChangeOrder: {
      type: Boolean,
      required: false
    },
    isDomainVisible: {
      type: Boolean,
      required: true,
      dafault: true
    }
  },

  components: {
    SubHeaderContainer,
    ImportPremiumPodcast,
    InputFilter
  },

  data() {
    return {
      checked: false,
      currentSlide: 0
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isPaidCustomer",
      "isPremium",
      "currentUser",
      "filters"
    ]),

    inputFilter() {
      return inputFilters;
    },

    isActivateDragAndDrop() {
      return this.isPremium;
    },

    isPremium() {
      return (
        (this.isAdmin || this.isPaidCustomer) &&
        this.currentUser.company !== null
      );
    }
  },

  methods: {
    searchPrograms(value) {
      this.$emit("searchPrograms", value);
    },
    changeFilterPlaylist(value) {
      this.$emit("changeFilterPlaylist", value);
    },
    changeSlide(slideIndex) {
      this.currentSlide = slideIndex;
    },
    openModal() {
      this.changeSlide(0);
      setTimeout(() => {
        this.$bvModal.show("modal-add-content");
      }, 200);
    },
    closeModalAndReload(programId) {
      this.$bvModal.hide("modal-add-content");
      this.$emit("refreshProgramList", programId);
    },
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        PAGE_PROGRAMS,
        this.currentUser.roles,
        field,
        accessType
      );
    }
  }
};
</script>

<style scoped>
.select-choice {
  cursor: pointer;
  color: black;
}

.select-choice:hover {
  background: #f7f7f7;
}

/**
 * usefull for vue transition
 */
.img-slider {
  overflow: hidden;
  position: relative;
  height: 200px;
  width: 400px;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>
