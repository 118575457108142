var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{ref:"form",attrs:{"role":"group"},on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center"},[_c('span',{staticClass:"headline w-100"})]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-left mt-4",attrs:{"xs":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("ADVANCED_SETTINGS.CONTENT.IMPORT.INPUT_FEEDURL_LABEL")))]),_c('b-form-group',{attrs:{"label-for":"input-feed","invalid-feedback":_vm.invalidFeedback,"state":_vm.state}},[_c('b-form-input',{staticClass:"mt-1",attrs:{"id":"input-feed","placeholder":_vm.$t(
                  'ADVANCED_SETTINGS.CONTENT.IMPORT.INPUT_FEEDURL_PLACEHOLDER'
                ),"state":_vm.state,"trim":"","type":"url"},on:{"input":_vm.inputChangeHandler},model:{value:(_vm.feedURL),callback:function ($$v) {_vm.feedURL=$$v},expression:"feedURL"}}),(_vm.hasAccess('cluster', 'write'))?_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.partitionList,"label":_vm.$t(
                  'ADVANCED_SETTINGS.CONTENT.IMPORT.SELECT_PARTITION_PLACEHOLDER'
                ),"required":""},model:{value:(_vm.partition),callback:function ($$v) {_vm.partition=$$v},expression:"partition"}}):_vm._e(),(!_vm.domainLoading)?_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.domains,"rules":[_vm.isRequired],"label":_vm.$t(
                  'ADVANCED_SETTINGS.CONTENT.IMPORT.SELECT_DOMAIN_PLACEHOLDER'
                ),"item-text":"name","return-object":"","required":""},model:{value:(_vm.seletedDomain),callback:function ($$v) {_vm.seletedDomain=$$v},expression:"seletedDomain"}}):_vm._e(),(_vm.domainLoading)?_c('div',{staticClass:"d-flex align-items-center text-center mt-4"},[_c('strong',[_vm._v("Domains loading...")]),_vm._v("   "),_c('b-spinner',{attrs:{"variant":"primary","label":"Loading"}})],1):_vm._e()],1)],1),(0 < _vm.errors.length)?_c('v-col',{attrs:{"xs":"12"}},[_c('ul',{staticClass:"text-danger",attrs:{"id":"errors-list"}},_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])}),0)]):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"type":"reset","variant":"danger"}},[_vm._v(_vm._s(_vm.$t("ADVANCED_SETTINGS.CONTENT.IMPORT.BUTTON.CANCEL")))]),_c('v-btn',{staticClass:"mx-1 btn",staticStyle:{"{\n            border-color":"#1c195c","color":"#ffffff","background-color":"#1c195c"},attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading || !_vm.state || !_vm.seletedDomain}},[_vm._v(_vm._s(_vm.$t("ADVANCED_SETTINGS.CONTENT.IMPORT.BUTTON.IMPORT"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }