<template>
  <v-dialog v-model="display" overlay persistent width="300">
    <v-card color="error" dark>
      <v-card-text class="pt-2">
        <p class="white--text">
          {{ $tc("PROGRAMS_PAGE.ERROR_LOADING") }}
        </p>
        <div class="text-center">
          <button
            class="btn btn-primary"
            onclick="document.location.reload(true);"
          >
            <i class="fas fa-redo"></i> {{ $tc("PROGRAMS_PAGE.LABEL_RELOAD") }}
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "content-load-error",
  props: {
    display: Boolean
  }
};
</script>
