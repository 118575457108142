// const routes = [];

export const getAttributesRead = (attributes, userRoles, values = null) => {
  let authorizedAttributes = {};

  for (const field in attributes) {
    const fieldAccess = values ? values[field] : attributes[field].defaultValue;

    if (hasAttributeAccess(attributes, userRoles, field, "read")) {
      authorizedAttributes = {
        ...authorizedAttributes,
        [field]: fieldAccess
      };
    }
  }

  return authorizedAttributes;
};

export const getAttributesWrite = (formAttributes, attributes, userRoles) => {
  let authorizedAttributes = {};

  for (const field in formAttributes) {
    if (!(field in attributes)) {
      continue;
    }

    if (hasAttributeAccess(attributes, userRoles, field, "write")) {
      const formValue = formAttributes[field];
      authorizedAttributes = {
        ...authorizedAttributes,
        [field]: formValue
      };
    }
  }

  return authorizedAttributes;
};

export const hasAttributeAccess = (
  attributes,
  userRoles,
  field,
  accessType
) => {
  if (!(field in attributes)) {
    console.error(field);
    throw new Error("Unsupported field.");
  }

  const fieldAccess = attributes[field];
  if (!(accessType in fieldAccess)) {
    console.error(field);
    throw new Error("Unsupported accessType.");
  }

  const attributeRoles = fieldAccess[accessType];
  if (attributeRoles.length === 0) {
    return false;
  }
  const intersection = userRoles.filter(userRole =>
    attributeRoles.includes(userRole)
  );
  return intersection.length > 0;
};
