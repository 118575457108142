var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"card border-0 h-100 container-podcast",class:{
    'bg-warning': '1' === _vm.isDisabled,
    'disable-click': _vm.isDisabled,
    'disable-color-item': _vm.isDisabled,
    'shadow-lg': _vm.podcast.id === _vm.lastProgramImport
  },attrs:{"title":_vm.getDataTooltip}},[(_vm.podcast.playlist)?_c('div',{staticClass:"ribbon ribbon-top-left"},[_c('span',[_vm._v(_vm._s(_vm.$tc("ADVANCED_SETTINGS.CONTENT.THUMBNAILS.PLAYLIST")))])]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end align-items-center pr-4 mt-1"},[_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.isNewProgram)?_c('span',{staticClass:"badge badge-success text-white mt-1"},[_vm._v(_vm._s(_vm.$tc("ADVANCED_SETTINGS.CONTENT.THUMBNAILS.NEW_PROGRAM")))]):_vm._e()]),_c('v-btn',{attrs:{"icon":"","color":_vm.podcast.homeDisplay ? 'green' : 'red',"title":_vm.podcast.homeDisplay ? 'Online' : 'Offline',"small":""}},[_c('v-icon',[_vm._v("mdi-checkbox-blank-circle")])],1)],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticStyle:{"border-radius":"0.75rem"},attrs:{"src":_vm.getImageSrc,"width":"200","height":"200","alt":"Thumbnails program"}})]),_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center",staticStyle:{"min-height":"103px"}},[_c('h4',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mb-0 py-4 text-center w-100 px-5",class:{
        'font-weight-bold': '1' === _vm.podcast.parentId
      },attrs:{"title":_vm.podcast.name}},[_vm._v(" "+_vm._s(_vm._f("truncateName")(_vm.podcast.name))+" ")]),_c('div',{staticClass:"position-absolute",staticStyle:{"right":"0px"}},[(!_vm.podcast.parentId)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,528074481)},[_c('v-list',[_vm._l((_vm.linksFeed),function(item,index){return [(_vm.menuAccess(item, _vm.podcast) && item.action !== 'edit')?_c('v-list-item',{key:`link_flux_${index}`,attrs:{"link":""},on:{"click":function($event){return _vm.action(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"pr-2",attrs:{"color":item.color}},[_vm._v("mdi-"+_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.label))],1)],1):_vm._e(),(item.action === 'edit')?_c('v-list-item',{key:`link_flux_${index}`,attrs:{"to":'/settings/content/' + _vm.podcast.id,"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"pr-2",attrs:{"color":item.color}},[_vm._v("mdi-"+_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.label))],1)],1):_vm._e()]})],2)],1):_vm._e()],1)]),_c('div',{staticClass:"d-flex flex-row justify-content-center align-items-center"})])
}
var staticRenderFns = []

export { render, staticRenderFns }