<template>
  <v-dialog
    v-model="display"
    hide-overlay
    persistent
    overlay-color="transparent"
    width="300"
  >
    <v-card color="voxeus_loader" dark>
      <v-card-text class="pt-2">
        <span class="white--text">{{ $tc("PROGRAMS_PAGE.LOADING") }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "content-load",
  props: {
    display: Boolean
  }
};
</script>
