<template>
  <div
    v-b-tooltip.hover
    :title="getDataTooltip"
    class="card border-0 h-100 container-podcast"
    v-bind:class="{
      'bg-warning': '1' === isDisabled,
      'disable-click': isDisabled,
      'disable-color-item': isDisabled,
      'shadow-lg': podcast.id === lastProgramImport
    }"
  >
    <div v-if="podcast.playlist" class="ribbon ribbon-top-left">
      <span>{{ $tc("ADVANCED_SETTINGS.CONTENT.THUMBNAILS.PLAYLIST") }}</span>
    </div>

    <div class="d-flex justify-content-end align-items-center pr-4 mt-1">
      <div class="d-flex justify-content-end">
        <span v-if="isNewProgram" class="badge badge-success text-white mt-1">{{
          $tc("ADVANCED_SETTINGS.CONTENT.THUMBNAILS.NEW_PROGRAM")
        }}</span>
      </div>

      <v-btn
        icon
        :color="podcast.homeDisplay ? 'green' : 'red'"
        :title="podcast.homeDisplay ? 'Online' : 'Offline'"
        small
      >
        <v-icon>mdi-checkbox-blank-circle</v-icon>
      </v-btn>
    </div>

    <div class="d-flex justify-content-center">
      <img
        :src="getImageSrc"
        width="200"
        height="200"
        alt="Thumbnails program"
        style="border-radius: 0.75rem"
      />
    </div>

    <div
      class="d-flex flex-row justify-content-between align-items-center"
      style="min-height: 103px"
    >
      <h4
        class="mb-0 py-4 text-center w-100 px-5"
        v-bind:class="{
          'font-weight-bold': '1' === podcast.parentId
        }"
        v-b-tooltip.hover
        :title="podcast.name"
      >
        {{ podcast.name | truncateName }}
      </h4>

      <div class="position-absolute" style="right: 0px">
        <v-menu v-if="!podcast.parentId" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <template v-for="(item, index) in linksFeed">
              <v-list-item
                v-if="menuAccess(item, podcast) && item.action !== 'edit'"
                @click="action(item)"
                :key="`link_flux_${index}`"
                link
                ><v-list-item-title
                  ><v-icon class="pr-2" :color="item.color"
                    >mdi-{{ item.icon }}</v-icon
                  >
                  {{ item.label }}</v-list-item-title
                ></v-list-item
              >
              <v-list-item
                :to="'/settings/content/' + podcast.id"
                v-if="item.action === 'edit'"
                :key="`link_flux_${index}`"
                link
                ><v-list-item-title
                  ><v-icon class="pr-2" :color="item.color"
                    >mdi-{{ item.icon }}</v-icon
                  >
                  {{ item.label }}</v-list-item-title
                ></v-list-item
              >
            </template>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div
      class="d-flex flex-row justify-content-center align-items-center"
    ></div>
  </div>
</template>

<script>
import auth from "@/store/auth.module";
import { LINKS_FEED } from "@/common/config/configuration/program";

const MAX_CHARACTERS_TITLE = 40;

export default {
  name: "podcasts",

  props: {
    podcast: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    lastProgramImport: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      user: auth.state.user
    };
  },

  computed: {
    getRssFeed() {
      const baseUrl = this.podcast.platformUrl;
      return `${baseUrl}/feed/rss/website.xml`;
    },

    getAtomFeed() {
      const baseUrl = this.podcast.platformUrl;
      return `${baseUrl}/feed/atom/website.xml`;
    },

    getPodcastFeed() {
      const baseUrl = this.podcast.platformUrl;
      return `${baseUrl}/feed/podcast/website.xml`;
    },

    getPodcastUrl() {
      return this.podcast.platformUrl;
    },

    isDisabled() {
      return this.podcast.parentId;
    },

    linksFeed() {
      return LINKS_FEED;
    },

    getDataTooltip() {
      const lastPublishDate = this.podcast.lastEpisodePublishedAt
        ? this.$d(new Date(this.podcast.lastEpisodePublishedAt), "short")
        : null;

      let tooltipContent = null;

      if (lastPublishDate) {
        tooltipContent = `Last publish: ${lastPublishDate}`;
      }

      return tooltipContent;
    },

    isNewProgram() {
      return this.podcast.id === this.lastProgramImport;
    },

    getImageSrc() {
      if (this.podcast?.thumbnails && 256 in this.podcast.thumbnails) {
        return this.podcast.thumbnails[256];
      }
      return this.podcast.image;
    }
  },

  methods: {
    menuAccess(item, data) {
      if (
        (item.action === "online" && data.homeDisplay) ||
        (item.action === "offline" && !data.homeDisplay)
      ) {
        return false;
      }

      if (
        this.podcast.playlist !== null &&
        (item.action === "importenabled" || item.action === "importdisabled")
      ) {
        return false;
      }

      if (
        (item.action === "importenabled" && data.status) ||
        (item.action === "importdisabled" && !data.status)
      ) {
        return false;
      }

      if (!this.isDisplayedFor(item.access)) {
        return false;
      }

      return true;
    },
    isDisplayedFor(access = []) {
      return this.user.roles.some(item => access.includes(item));
    },
    action(item) {
      switch (item.action) {
        case "importenabled":
        case "importdisabled":
          this.$emit("importstatusProgram", this.podcast);
          break;
        case "online":
        case "offline":
          this.$emit("homedisplayProgram", this.podcast);
          break;
        case "episodes":
          this.$emit("modalEpisodes", this.podcast);
          break;
        case "cleanimages":
          this.$emit("cleanimages", this.podcast);
          break;
        case "edit":
          {
            const link = "/settings/content/" + this.podcast.id;
            this.$router.push(link);
          }
          break;
        case "delete":
          this.$emit("deleteProgram", this.podcast.id);
          break;
        case "broadcast":
          this.$emit("openProgram", this.podcast);
          break;
      }
    }
  },

  filters: {
    truncateName(value) {
      let name = value;

      if (name.length >= MAX_CHARACTERS_TITLE) {
        name = `${name.substring(0, MAX_CHARACTERS_TITLE)}...`;
      }

      return name;
    }
  }
};
</script>

<style scoped>
.container-podcast {
  padding-left: 5px;
  padding-right: 5px;
  min-height: 315px;
}

.podcast_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.podcast_meta_figure {
  font-weight: 600;
}

.kt-svg-icon svg {
  max-width: 100%;
  max-height: 100%;
}

.enable-click {
  pointer-events: auto !important;
}

.disable-click {
  pointer-events: none;
}

.disable-color-item {
  background-color: rgba(202, 202, 202, 0.5);
}

.v-list-item .v-list-item__title {
  overflow-y: hidden;
}

.link-color {
  color: #595d6e;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 37px;
  background-color: #ff961d;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 13px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -20px;
  left: -10px;
}

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
</style>
